import { usePage } from '@inertiajs/react';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export default function BadgeEarned() {
	const {
		auth: { user }
	} = usePage().props;

	useEffect(() => {
		window.Echo.private(
			`App.Models.User.${user.id}`
		).listen('BadgeEarned', (e) => {
			toast(`🎉 New Badge Earned`, {
				position: 'top-right',
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: false,
				theme: 'dark'
			});
		});

		return () => {
			window.Echo.leave(`App.Models.User.${user.id}`);
		};
	}, []);

	return (
		<>
			<ToastContainer
				limit={2}
				toastClassName={({ type }) =>
					'relative flex p-4 min-h-10 my-4 rounded-md !bg-base-300 justify-between overflow-hidden cursor-pointer'
				}
				bodyClassName={() =>
					'text-sm font-white font-med block p-3'
				}
			/>
		</>
	);
}
