import { Link, usePage } from '@inertiajs/react';
import ApplicationLogo from '@/Components/ApplicationLogo';
import Toast from '@/Components/Toast';
import MobileNav from '@/Components/MobileNav';
import Header from './Partials/Header';
import FeedbackButton from '@/Components/FeedbackButton';
import BadgeEarned from '@/Components/BadgeEarned';
import UserOnboarding from '@/Components/Modals/UserOnboarding';

export default function Main({ children }) {
	const {
		auth: { user }
	} = usePage().props;

	const currentYear = new Date().getFullYear();

	return (
		<>
			<div className="flex flex-col">
				<Header />

				<main>{children}</main>

				<div>
					<div className="bg-base-200 text-neutral-content">
						<footer className="footer container mx-auto px-4 py-20">
							<div>
								<ApplicationLogo className="mb-2" />
								<p class="text-mute">
									&copy; Copyright {currentYear}
									<br />
									All trademarks
									referenced on this site
									are property of their
									respective owners.
								</p>
							</div>
							<div className='uppercase'>
								<span className="footer-title text-lg">
									Search
								</span>
								<Link
									href={route(
										'championships'
									)}
									className="link link-hover"
								>
									Championships
								</Link>
								<Link
									href={route('events')}
									className="link link-hover"
								>
									Events
								</Link>
								<Link
									href={route(
										'communities'
									)}
									className="link link-hover"
								>
									Communities
								</Link>
								<Link
									href={route(
										'time.trials'
									)}
									className="link link-hover"
								>
									Time Trials
								</Link>
								<Link
									href={route('teams')}
									className="link link-hover"
								>
									Teams
								</Link>
								<Link
									href={route('marketplace')}
									className="link link-hover"
								>
									Marketplace
								</Link>
							</div>
							<div className='uppercase'>
								<span className="footer-title text-lg">
									Only Racing
								</span>
								<Link
									href={route('advertise')}
									className="link link-hover"
								>
									Advertise
								</Link>
								<Link
									href={route(
										'blogs.index'
									)}
									className="link link-hover"
								>
									Posts
								</Link>
							</div>
							<div className='uppercase'>
								<span className="footer-title text-lg">
									Account
								</span>
								<Link
									href={route('login')}
									className="link link-hover"
								>
									Login / Register
								</Link>
								<Link
									href={route('terms')}
									className="link link-hover"
								>
									Terms of service
								</Link>
								<Link
									href={route('privacy')}
									className="link link-hover"
								>
									Privacy policy
								</Link>
							</div>
							<div className='uppercase'>
								<span className="footer-title text-lg">
									Social
								</span>
								<div className="grid grid-flow-col gap-4">
									<a
										href="https://www.facebook.com/profile.php?id=61550599465288"
										target="_blank"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											className="fill-current"
											viewBox="0 0 512 512"
										>
											<path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
										</svg>
									</a>
									<a
										href="https://www.instagram.com/official_onlyracing/"
										target="_blank"
									>
										<svg 
											xmlns="http://www.w3.org/2000/svg" 
											height="24" 
											width="24" 
											className="fill-current"
											viewBox="0 0 448 512">
											<path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
										</svg>
									</a>
								</div>
							</div>
						</footer>
					</div>
				</div>
			</div>
			<MobileNav />
			<Toast />
			{user && <BadgeEarned />}
			{user && <UserOnboarding />}
		</>
	);
}
