import { usePage } from '@inertiajs/react'
import NavLink from './NavLink';

export default function MobileNav() {
    const { auth } = usePage().props;

    return (
        <div className="btm-nav bg-primary z-10 md:hidden">
            <NavLink href={route('championships')} active={route().current('championships' + '*')} className={`${route().current('championships' + '*') ? 'bg-secondary' : ''}`}>
                <span className="material-icons-outlined">emoji_events</span>
            </NavLink>
            <NavLink href={route('events')} active={route().current('events' + '*')} className={`${route().current('events' + '*') ? 'bg-secondary' : ''}`}>
                <span className="material-icons-outlined">calendar_month</span>
            </NavLink>
            <NavLink href={route('communities')} active={route().current('communities' + '*')} className={`${route().current('communities' + '*') ? 'bg-secondary' : ''}`}>
                <span className="material-icons-outlined">people</span>
            </NavLink>
            <NavLink href={route('time.trials')} active={route().current('time.trials' + '*')} className={`${route().current('time.trials' + '*') ? 'bg-secondary' : ''}`}>
                <span className="material-icons-outlined">timer</span>
            </NavLink>
            <NavLink href={route('marketplace')} active={route().current('marketplace' + '*')} className={`${route().current('marketplace' + '*') ? 'bg-secondary' : ''}`}>
                <span className="material-icons-outlined">paid</span>
            </NavLink>
            {auth.user ? (
                <>
                    <NavLink href={route('user.account.edit')} active={route().current('user.account.edit')} className={`${route().current('user.account.edit') ? 'bg-secondary' : ''}`}>
                        <span className="material-icons-outlined">settings</span>
                    </NavLink>
                </>
            ) : (
                <>
                    <NavLink href={route('login')} active={route().current('login')} className={`${route().current('login') ? 'bg-secondary' : ''}`}>
                        <span className="material-icons-outlined">person</span>
                    </NavLink>
                </>
            )}
        </div>
    );
}
